<template>
   <!-- Login content -->
   <div>
      <div class="card-body">
         <p class="login-box-msg">Informe suas credenciais para entrar</p>

         <form @submit.prevent="loginUser">
            <div class="input-group mb-3">
               <input type="text" v-model="auth.usr"
                      class="form-control" placeholder="Email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="password" v-model="auth.pwd"
                      class="form-control" placeholder="Senha" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-lock"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button type="submit" class="btn btn-block btn-outline-secondary"><b>Entrar</b></button>
               </div>
               <!-- /.col -->
            </div>
         </form>

         <p class="mb-1 mt-4">
            <router-link :to="{name: 'forgot-password'}" class="text-gray-dark">Esqueci a minha senha</router-link>
         </p>
         <p class="mb-1">
            <router-link :to="{name: 'register'}" class="text-gray-dark">Registre-se</router-link>
         </p>
      </div>
      <!-- /.card-body -->

   </div>

</template>


<script>
    export default {
        name: 'Login',
        props: {},
        data(){
            return {
                auth: {
                    usr: '',
                    pwd: ''
                }
            }
        },
        methods: {
            async loginUser(){
               this.$router.push({ path: '/dashboard' });
            }
        }
    }
</script>
